import { useEffect, useState } from "react";
import { Input, Select, notification } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import { DatePicker, Space, Tooltip, Button } from 'antd';
import dayjs from 'dayjs';
import Text from "antd/es/typography/Text";
import { KilnTypeUIRenderer, genRendererMap } from "../kiln_types/factory";
import { KilnType } from "../kiln_types/common";
import { KilnLogTable } from "../widgets/datasets/kiln_log_table";

const { RangePicker } = DatePicker;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare type EventValue<Dayjs> = dayjs.Dayjs | null;
declare type RangeValue<Dayjs> = [EventValue<Dayjs>, EventValue<Dayjs>] | null;

function ArchivedKilnLogs(props : any) {

  const [logs, setLogs] = useState<any[]>([]);
  const [facilities, setFacilities] = useState<any[]>([]);
  
  const defaultPreDate: dayjs.Dayjs = dayjs().subtract(2, 'month');

  const kilnTypeRenderMap: Map<KilnType, KilnTypeUIRenderer> = genRendererMap();
  
  const [searchParams, setSearchParams] = useState<any>({
    facility_id: 0,
    kilnid: "",
    archivefrom: defaultPreDate,
    archiveto: dayjs(),
    trigger: 0,
  });

  useEffect(() => {
    const executeSearch = async () => {
      await fetch('/api/kiln/archives?' + new URLSearchParams({
        af: searchParams.archivefrom.toISOString().split('T')[0],
        at: searchParams.archiveto.add(1, 'day').toISOString().split('T')[0],
        id: searchParams.kilnid,
        fi: searchParams.facility_id,
      }), {
        headers: {
          'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
          },
      }).then(response => {
        if (response.ok) {
          return response.json();
        }
        notification.error({
          message: 'Error retrieving kiln logs.',
          description: 'An unknown error occurred retrieving the kiln logs. Please try again later.',
          duration: 4,
          placement: "top",
        });
        return null;
      })
      .then(data => {
        if (data !== null) {
          setLogs(data.kilnlogs);
          setFacilities(data.facilities);
        }
      })
      .catch(err => {
        console.log("error " + err);
      });
    }

    executeSearch();
  }, [searchParams])

  function KilnSearchInputs(props: any) {

    const [searchBuffer, setSearchBuffer] = useState<any>({
      facility_id: searchParams.facility_id,
      kilnid: searchParams.kilnid,
      archivefrom: searchParams.archivefrom,
      archiveto: searchParams.archiveto,
    });

    const onChangeArchiveDateRange = (value: RangeValue<dayjs.Dayjs>) => {
      setSearchBuffer({
        ...searchBuffer,
        'archivefrom': value?.[0],
        'archiveto': value?.[1]
      });
    };
  
    const onChange = (e: any) => {
      setSearchBuffer({
        ...searchBuffer,
        'kilnid': e.target.value,
      });
    };
  
    const updateSearchParams = () => {
      setSearchParams({
        ...searchParams,
        'archivefrom': searchBuffer.archivefrom,
        'archiveto': searchBuffer.archiveto,
        'kilnid': searchBuffer.kilnid,
        'facility_id': searchBuffer.facility_id,
      });
    }

    const handleFacilityChange = (value: string) => {
      setSearchBuffer({
        ...searchBuffer,
        'facility_id': value,
      });
    }

    return (
      <>
        <Space>
          <Text>Facility:</Text>
          <Tooltip title="Restrict to one facility.">
            <Select
              style={{ width: 200 }}
              onChange={handleFacilityChange}
              options={facilities}
              value={searchBuffer.facility_id}
            />
          </Tooltip>
          <Text>Kiln:</Text>
          <Tooltip title="Enter a specific kiln id to see only results for that kiln.">
            <Input style={{ width: 150 }} defaultValue={searchBuffer.kilnid} onChange={onChange} onPressEnter={updateSearchParams}/>
          </Tooltip>
          <Text>Archival Date:</Text>
          <Tooltip title="Archival date range to search (inclusive)">
            <RangePicker allowClear={false} allowEmpty={[false, false]} defaultValue={[searchBuffer.archivefrom, searchBuffer.archiveto]} onChange={onChangeArchiveDateRange} />
          </Tooltip>
          <Tooltip title="Search">
            <Button type="primary" shape="circle" icon={<SearchOutlined />} onClick={updateSearchParams} />
          </Tooltip>
        </Space>
      </>
    )
  }

  // a hack to cause a change to the search data, so a re-search is triggered to populate
  // the displayed table.
  function refresh(): void {
    setSearchParams({
      ...searchParams, 
      trigger: searchParams.trigger + 1});
  }

  function removeLocalLog(id: string) : void {
    setLogs(logs.filter(l => l.id !== id));
  }

  return (
    <div className="wrapper">
    <h1>Archived Kiln Logs</h1>
    <Space direction="vertical">
      <KilnSearchInputs/>
      <KilnLogTable
        archive_mode={true}
        logs={logs} 
        rendererMap={kilnTypeRenderMap} 
        refreshCallback={refresh} 
        removeLocalLogFunction={removeLocalLog}/>
    </Space>
  </div>
  );
}

export default ArchivedKilnLogs;