import { useContext, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { Menu, MenuProps, Layout } from "antd";
import {HomeOutlined, UserOutlined, TeamOutlined, EnvironmentOutlined, GiftOutlined, AuditOutlined, LogoutOutlined, ExperimentOutlined, FileExcelOutlined, BarChartOutlined, SettingOutlined, DeleteOutlined} from '@ant-design/icons';
import { AbilityContext } from "../permissions";

const { Header, Content} = Layout;

const headerStyle: React.CSSProperties = {
  height: 64,
  lineHeight: '64px',
};

const HeaderSection = ({jwt, logout}: any) => {
  const [current, setCurrent] = useState<string>('home');
  const ability = useContext(AbilityContext);
  
  const showLogout: boolean = jwt != null;

  const onClick: MenuProps['onClick'] = (e) => {
    console.log('click ', e);
    setCurrent(e.key);
    if (e.key === 'logout') {
      logout();
    }
  };

  // define the menu contents
  type MenuItem = Required<MenuProps>['items'][number];
  
  const items: MenuItem[] = [
    {
      label: <Link to={'/home'} >Home</Link>,
      key: 'home',
      icon: <HomeOutlined />,
      children: [
        { label: <Link to={`/profile`} >Profile</Link>, 
          key: 'profile', icon: <UserOutlined /> },
      ],
    },
  ];

  // Configure Menu (facility, users, kilns)
  if (ability.can('read', 'facility') || ability.can('read', 'kiln') || ability.can('create', 'user'))
  {
    const configureMenu: MenuItem[] = [];    
    const configSubmenu: MenuItem[] = [];
    
    if (ability.can('read', 'facility')) {
      configSubmenu.push({
        label: <Link to={`/facilities`} >Facilities</Link>,
        key: 'facilities',
        icon: <EnvironmentOutlined />,
      });
    }

    if (ability.can('read', 'kiln')) {
      configSubmenu.push({
        label: <Link to={`/kiln_reg`} >Kilns</Link>,
          key: 'kiln',
          icon: <ExperimentOutlined />,
      });
    }

    if (ability.can('create', 'user')) {
      configSubmenu.push({
        label: <Link to={`/users`} >Users</Link>,
          key: 'users',
          icon: <TeamOutlined />,
      });
    }

    configureMenu.push({
      label: 'Configure',
      key: 'config',
      icon: <SettingOutlined />,
      children: configSubmenu,
    });
    items.push.apply(items, configureMenu);
  }

  // Analyse Menu
  if (ability.can('read', 'kilnlog')) {
    const analyseMenu: MenuItem[] = [];    
    const analyseSubmenu: MenuItem[] = [];
    
    if (ability.can('read', 'kilnlog')) {
      analyseSubmenu.push({
        label: <Link to={`/kiln_logs`} >Kiln Logs</Link>,
        key: 'kilnlogs',
        icon: <FileExcelOutlined />,
      });
      analyseSubmenu.push({
        label: <Link to={`/archived_kiln_logs`} >Archived Kiln Logs</Link>,
        key: 'archivedlogs',
        icon: <DeleteOutlined />,
      });
    }

    analyseMenu.push({
      label: 'Analyse',
      key: 'analyse',
      icon: <BarChartOutlined />,
      children: analyseSubmenu,
    });
    items.push.apply(items, analyseMenu);
  }

  // will likely want an Audit menu, for auditors to view ready capsules
  if (ability.can('read', 'capsule')) {
    const auditMenu: MenuItem[] = [];    
    const auditSubmenu: MenuItem[] = [];
    
    if (ability.can('read', 'capsule')) {
      auditSubmenu.push({
        label: <Link to={`/capsules`} >Capsules</Link>,
        key: 'capsules',
        icon: <GiftOutlined />,
      });
    }

    auditMenu.push({
      label: 'Audit',
      key: 'audit',
      icon: <AuditOutlined />,
      children: auditSubmenu,
    });

    items.push.apply(items, auditMenu);
  }

  if (showLogout) {
    items.push({
      label: 'Logout',
      key: 'logout',
      icon: <LogoutOutlined />,
    });
  }
  
  return (
    <>
      <Header style={headerStyle}>
        <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items} />
      </Header>
      <Content>
        <Outlet />
      </Content>
    </>
  );
};

export default HeaderSection;